/**
 * styles for simple block to show jsx code written in form
 * create @nk24@CMScom@2023/10/3
 */

.Static {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Static code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Static {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.Static .hero_img {
  margin: 2rem auto;
  width: 100%;
  max-width: 1000px;
  height: 500px;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center; */
}
.Static .hero_img .img {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;
}
.Static .hero_img .catch_copy {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  color: #004c82;
}
@media (min-width: 321px) {
  .Static .hero_img .img {
    max-width: calc(100vw - 48px);
  }
  .Static .hero_img .catch_copy {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .Static .hero_img .img {
    max-width: 550px;
  }
  .Static .hero_img .catch_copy {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .Static .hero_img .img {
    max-width: 800px;
  }
  .Static .hero_img .catch_copy {
    margin-top: -5.5em;
    padding-left: 2em;
    width: 800px;
    font-size: 2rem;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .Static .hero_img .img {
    max-width: 1000px;
  }
  .Static .hero_img .catch_copy {
    width: 1000px;
    font-size: 2.5rem;
  }
}
