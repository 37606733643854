/*******************************
        Theme Selection
*******************************/
/* To override a theme for an individual element specify theme name below */
/* Global */
/* Elements */
/* Collections */
/* Modules */
/* Views */
/* Extras */
/*******************************
            Folders
*******************************/
/* Path to theme packages */
/* Path to site override folder */
/*******************************
         Import Theme
*******************************/
/*******************************
        Import Directives
*******************************/
/*------------------
       Theme
-------------------*/
/*--------------------
   Site Variables
---------------------*/
/* Default site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
    Border Radius
--------------------*/
/* See Power-user section below
   for explanation of @px variables
*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/*-------------------
    Focused Input
--------------------*/
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/* Inverted */
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/* For source only. Modified in gulp for dist */
/*-------------------
       Em Sizes
--------------------*/
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/* Maximum Glyph Width of Icon */
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Packaged site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*------------------
     Animations
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*-------------------
      Opacities
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Component's site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*------------------
     Animations
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*-------------------
      Opacities
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Site theme site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*--- Overrides default colors ---*/
/*---  Dispatch colors to elements  ---*/
/*-------------------
 Component Variables
---------------------*/
/* Default */
/* Packaged Theme */
/* Site Theme */
/*******************************
             Mix-ins
*******************************/
/*------------------
       Fonts
-------------------*/
/*------------------
     Overrides
-------------------*/
/* End Config */
.ui.basic.segment.header-wrapper {
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 5px 0 0;
}
.ui.basic.segment.header-wrapper .ui.container {
  margin-right: 0 !important;
}
.ui.basic.segment.header-wrapper .ui.container .header .logo-nav-wrapper .logo {
  padding-top: 0;
}
.ui.basic.segment.header-wrapper .ui.container .header .logo-nav-wrapper .ContactToLink {
  display: inline;
}
@media only screen and (max-width: 767px) {
  .ui.segment.header-wrapper .ui.container .header .logo-nav-wrapper .logo {
    padding-top: 30px;
  }
  .ui.segment.header-wrapper .ui.container .header .logo-nav-wrapper .ContactToLink {
    display: none;
  }
}
